<template>
  <v-expansion-panel>
    <expansion-header :text="$t('coastMenu.waveData')"></expansion-header>
    <v-expansion-panel-content>
      <near-real-time
        :station-list="stationList"
        :parameter-list="parameterList"
      ></near-real-time>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ExpansionHeader from "@/core/components/menu/coastMenu/ExpansionHeader.vue";
import axios from "axios";
import NearRealTime from "@/core/components/menu/coastMenu/NearRealTime.vue";

export default {
  name: "WaveDataEntry",
  components: { NearRealTime, ExpansionHeader },
  data: () => ({
    stationList: [],
    parameterList: []
  }),
  methods: {
    async fetchWaveParameter() {
      const response = await axios.get(`/external/qld/waves/parameters`);
      return response.data;
    },
    groupParametersForPLots(response, params, parameterName) {
      let data = [];
      params.forEach(param => {
        const filtered = response.filter(parameter => parameter.name == param);
        data.push(filtered[0]);
      });
      return { full_name: parameterName, data: data, type: "waves" };
    },
    async fetchTideStations() {
      const response = await axios.get(`/external/qld/waves/sites`);
      const filtered = response.data.filter(
        site => site.site_name == "Noosa" || site.site_name == "Mooloolaba"
      );
      return filtered;
    }
  },

  async created() {
    this.fetchWaveParameter().then(response => {
      this.parameterList.push(
        this.groupParametersForPLots(response, ["Hsig", "Hmax"], "Wave height")
      );
      this.parameterList.push(
        this.groupParametersForPLots(response, ["Tz", "Tp"], "Wave period")
      );
      this.parameterList.push(
        this.groupParametersForPLots(
          response,
          ["Current Speed"],
          "Current speed"
        )
      );
      this.parameterList.push(
        this.groupParametersForPLots(
          response,
          ["Current Direction"],
          "Current direction"
        )
      );
      this.parameterList.push(
        this.groupParametersForPLots(
          response,
          ["SST"],
          "Sea surface temperature"
        )
      );
    });
    this.stationList = await this.fetchTideStations();
  }
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
