<template>
  <v-expansion-panel-content>
    <v-select
      :label="$t('datetime')"
      :items="datetimeSelection"
      :item-text="datetimeFormat"
      return-object
      hide-details
      v-model="selectedStep"
    >
      <v-icon
        slot="prepend"
        :disabled="disableNextButton(datetimeSelection, selectedStep)"
        @click="selectedStep = previousDate(datetimeSelection, selectedStep)"
      >
        mdi-menu-left
      </v-icon>
      <v-icon
        slot="append-outer"
        :disabled="disablePreviousButton(datetimeSelection, selectedStep)"
        @click="selectedStep = nextDate(datetimeSelection, selectedStep)"
      >
        mdi-menu-right
      </v-icon>
    </v-select>
  </v-expansion-panel-content>
</template>

<script>
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import { mapActions, mapState } from "vuex";

export default {
  name: "SingleDataEntry",
  mixins: [coastLayerMixin],
  data: () => ({
    selectedStep: null,
    datetimeSelection: []
  }),
  props: {
    selectedLayer: Object,
    panel: Number,
    index: Number,
    layerSelection: Array
  },
  computed: {
    ...mapState("raster", ["activeRasterLayer"])
  },
  methods: {
    ...mapActions("raster", ["setLayerTimesteps"]),
    async fetchTimestepsLocal(layerId) {
      const response = await this.$rastless.get(
        `/layers/${layerId}/steps?token=${this.accessToken}`
      );
      const data = response.data;
      this.setLayerTimesteps(data);
      return data;
    }
  },
  watch: {
    selectedStep() {
      if (this.selectedStep) {
        this.addLayers(
          this.selectedLayer.layerId,
          this.selectedStep,
          this.accessToken,
          "visualize"
        );
        this.$emit("changeInStep", this.selectedStep, this.selectedLayer);
      }
    },
    async panel() {
      if (this.panel !== undefined) {
        if (this.panel === this.index) {
          this.datetimeSelection = await this.fetchTimestepsLocal(
            this.selectedLayer.layerId
          );
          this.selectedStep = this.datetimeSelection[0];
        } else {
          this.removeRasterLayer(this.selectedLayer.layerId, "raster");
          this.removeRasterLayer(this.selectedLayer.layerId, "raster-dem");
        }
      } else {
        this.setLayerTimesteps([]);
        this.$store.dispatch("raster/setActiveLayer", {});
      }
    },
    "activeRasterLayer.layerId"() {
      if (
        this.activeRasterLayer?.layerId &&
        this.activeRasterLayer.product !== "sdb" &&
        this.activeRasterLayer.product !== "dem"
      ) {
        this.$emit("definePanel");
      }
      if (!this.activeRasterLayer?.layerId) {
        this.$emit("definePanel");
      }
    }
  },
  async created() {
    if (this.panel === this.index) {
      this.datetimeSelection = await this.fetchTimestepsLocal(
        this.selectedLayer.layerId
      );
      this.selectedStep = this.datetimeSelection[0];
      this.addLayers(
        this.selectedLayer.layerId,
        this.selectedStep,
        this.accessToken,
        "visualize"
      );
    } else {
      this.removeRasterLayer(this.selectedLayer.layerId, "raster");
      this.removeRasterLayer(this.selectedLayer.layerId, "raster-dem");
      // this.setLayerTimesteps([]);
      // this.$store.dispatch("raster/setActiveLayer", {});
    }
  }
};
</script>

<style scoped></style>
