<template>
  <div
    style="width: 100%; background-color: rgba(29,66,129,0.77); color: white"
  >
    <v-divider />
    <v-icon color="white" class="ml-2">{{ icon }}</v-icon>
    <span> {{ titel }} </span>
  </div>
</template>

<script>
export default {
  name: "Divider",
  props: {
    titel: String,
    icon: String
  }
};
</script>

<style scoped></style>
