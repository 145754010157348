import { render, staticRenderFns } from "./VisualizeCoastMenu.vue?vue&type=template&id=4bcc99a2&scoped=true"
import script from "./VisualizeCoastMenu.vue?vue&type=script&lang=js"
export * from "./VisualizeCoastMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bcc99a2",
  null
  
)

export default component.exports