<template>
  <core-menu>
    <region-menu-header
      name="visualize"
      icon="mdi-layers"
      :disable-region-menu="false"
      :disable-data-request="true"
    />
    <v-divider />
    <v-divider />
    <v-expansion-panels
      v-model="panel"
      accordion
      multiple
      focusable
      v-if="mapLoaded && !loading"
    >
      <divider
        :icon="'mdi-satellite-variant'"
        :titel="'Earth observation data'"
      ></divider>
      <depth-elevation-shoreline-entry
        :place="'visualize'"
        :raster-layer="rasterLayer"
        @setActiveLayer="toggleActiveLayerVuex"
      ></depth-elevation-shoreline-entry>
      <water-quality-entry
        :place="'visualize'"
        :raster-layer="rasterLayer"
        @setActiveLayer="toggleActiveLayerVuex"
      ></water-quality-entry>
      <divider :icon="'mdi-ruler'" :titel="'In situ data'"></divider>
      <tide-data-entry></tide-data-entry>
      <wave-data-entry></wave-data-entry>
      <divider :icon="'mdi-compare'" :titel="'Analysis layer'"></divider>
      <change-map-entry
        :place="'visualize'"
        :raster-layer="rasterLayer"
        @setActiveLayer="toggleActiveLayerVuex"
      ></change-map-entry>
    </v-expansion-panels>
  </core-menu>
</template>

<script>
import RegionMenuHeader from "@/core/components/menu/RegionMenuHeader.vue";
import { mapActions, mapState } from "vuex";
import DepthElevationShorelineEntry from "@/core/components/menu/coastMenu/DepthElevationShorelineEntry.vue";
import CoreMenu from "@/core/components/menu/CoreMenu.vue";
import WaterQualityEntry from "@/core/components/menu/coastMenu/WaterQualityEntry.vue";
import ChangeMapEntry from "@/core/components/menu/coastMenu/ChangeMapEntry.vue";
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import TideDataEntry from "@/core/components/menu/coastMenu/TideDataEntry.vue";
import WaveDataEntry from "@/core/components/menu/coastMenu/WaveDataEntry.vue";
import Divider from "@/core/components/menu/coastMenu/Divider.vue";

export default {
  name: "VisualizeCoastMenu",
  components: {
    Divider,
    WaveDataEntry,
    TideDataEntry,
    ChangeMapEntry,
    WaterQualityEntry,
    CoreMenu,
    DepthElevationShorelineEntry,
    RegionMenuHeader
  },
  mixins: [coastLayerMixin],
  data: () => ({
    rasterLayer: [],
    panel: [0],
    loading: true
  }),
  computed: {
    ...mapState("app", ["visualizeMapLoaded"]),
    mapLoaded() {
      return this.visualizeMapLoaded;
    }
  },
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("vector", ["fetchVectorLayer"]),
    toggleActiveLayerVuex({ datetime, layer }) {
      const activeLayerInfo = {
        ...datetime,
        ...layer
      };
      this.$store.dispatch("raster/setActiveLayer", activeLayerInfo);
    }
  },
  created() {
    this.getOrFetchAccessToken().then(async () => {
      this.rasterLayer = await this.fetchLayers();
      this.loading = false;
    });
  }
};
</script>

<style scoped></style>
