import { render, staticRenderFns } from "./SingleDataEntry.vue?vue&type=template&id=4df837ce&scoped=true"
import script from "./SingleDataEntry.vue?vue&type=script&lang=js"
export * from "./SingleDataEntry.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4df837ce",
  null
  
)

export default component.exports