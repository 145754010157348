<template>
  <v-expansion-panels accordion focusable v-model="panel">
    <v-expansion-panel
      v-for="parameter in parameterList"
      :key="parameter.full_name"
    >
      <expansion-header
        :text="parameter.full_name"
        :class-properties="'subtitle-1 py-0'"
      ></expansion-header>

      <v-expansion-panel-content>
        <v-card
          @click="setData(site)"
          class="pa-2"
          elevation="0"
          outlined
          v-for="site in stationList"
          :key="site.site_name"
          v-model="selectedSite"
          >{{ site.site_name }}
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ExpansionHeader from "@/core/components/menu/coastMenu/ExpansionHeader.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";
import bbox from "@turf/bbox";
import circle from "@turf/circle";
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";

export default {
  name: "NearRealTime",
  props: {
    parameterList: Array,
    stationList: Array
  },
  mixins: [coastLayerMixin],
  components: { ExpansionHeader },
  data: () => ({
    selectedSite: null,
    panel: null
  }),
  computed: {
    ...mapState("raster", ["activeRasterLayer"])
  },
  methods: {
    ...mapActions("coastsPlot", [
      "setParameter",
      "setSite",
      "setShowStationPlot"
    ]),
    ...mapActions("raster", ["setLayerTimesteps"]),
    setData(value) {
      this.setParameter(this.parameterList[this.panel]);
      this.setSite(value);
      this.setShowStationPlot(true);
      value.id = value.site_id;
      this.zoomToVirtualStation(value);

      this.removeRasterLayer(this.activeRasterLayer.layerId, "raster");
      this.removeRasterLayer(this.activeRasterLayer.layerId, "raster-dem");
      this.setLayerTimesteps([]);
      this.$store.dispatch("raster/setActiveLayer", {});
    },
    zoomToVirtualStation(item) {
      var geojson = {
        type: "Point",
        coordinates: [item.lon, item.lat]
      };
      let geojson_bbox = bbox(geojson);
      const myCircle = circle(geojson, 0.1, {
        steps: 10,
        units: "kilometers"
      });
      geojson_bbox = bbox(myCircle);

      this.$store.visualizeMap.resize();
      this.$store.visualizeMap.fitBounds(geojson_bbox, { padding: 300 });
    },
    defineSource(geojson) {
      geojson.features.forEach(feature => {
        feature.id = feature.properties.site_id;
      });
      return { type: "geojson", data: geojson };
    },
    addPoints(sourceData, sourceName, layerName) {
      const map = this.$store.visualizeMap;
      const sourceDataGeojson = this.defineSource(sourceData);
      if (!map.getLayer(layerName)) {
        map.addSource(sourceName, sourceDataGeojson);
        map.addLayer({
          id: layerName,
          source: sourceName,
          type: "circle",
          paint: {
            "circle-radius": 5,
            "circle-color": "#40ADD5"
          }
        });
      }
    },
    async fetchTidesSitesGeojson() {
      const response = await axios.get(`/external/qld/tides/sites?geojson`);
      return response.data;
    },
    async fetchWaveSitesGeojson() {
      const response = await axios.get(`/external/qld/waves/sites?geojson`);
      return response.data;
    },
    async addStationsToMap() {
      this.fetchTidesSitesGeojson().then(result => {
        const filtered = result.features.filter(
          site => site.properties.site_name == "maroochydore"
        );
        result.features = filtered;
        this.addPoints(result, "tides-source", "tides-layer");
      });
      this.fetchWaveSitesGeojson().then(result => {
        const filtered = result.features.filter(
          site =>
            site.properties.site_name == "Noosa" ||
            site.properties.site_name == "Mooloolaba"
        );
        result.features = filtered;
        this.addPoints(result, "waves-source", "waves-layer");
      });
    }
  },
  created() {
    this.addStationsToMap();
    if (this.parameterList.length === 1) {
      this.panel = 0;
    }
    if (this.stationList.length === 1) {
      this.setData(this.stationList[0]);
    }
  }
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
