<template>
  <v-expansion-panel>
    <expansion-header :text="$t('coastMenu.waterQuality')"></expansion-header>
    <v-expansion-panel-content>
      <v-expansion-panels accordion focusable v-model="panel">
        <v-expansion-panel v-for="layer in layerSelection" :key="layer.layerId">
          <expansion-header
            :text="layer.title"
            :class-properties="'subtitle-1 py-0'"
          ></expansion-header>
          <v-expansion-panel-content>
            <v-select
              v-if="place === 'visualize'"
              :label="$t('datetime')"
              :items="datetimeSelection"
              :item-text="datetimeFormat"
              return-object
              hide-details
              v-model="selectedStep"
            >
              <v-icon
                v-if="place === 'visualize'"
                slot="prepend"
                :disabled="disableNextButton(datetimeSelection, selectedStep)"
                @click="
                  selectedStep = previousDate(datetimeSelection, selectedStep)
                "
              >
                mdi-menu-left
              </v-icon>
              <v-icon
                v-if="place === 'visualize'"
                slot="append-outer"
                :disabled="
                  disablePreviousButton(datetimeSelection, selectedStep)
                "
                @click="
                  selectedStep = nextDate(datetimeSelection, selectedStep)
                "
              >
                mdi-menu-right
              </v-icon>
            </v-select>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import ExpansionHeader from "@/core/components/menu/coastMenu/ExpansionHeader.vue";
import { mapState } from "vuex";

export default {
  name: "WaterQualityEntry",
  components: { ExpansionHeader },
  mixins: [coastLayerMixin],
  data: () => ({
    panel: null,
    selectedLayer: null,
    selectedStep: null,
    datetimeSelection: [],
    layerSelection: []
  }),
  props: {
    rasterLayer: Array,
    place: String
  },
  computed: {
    ...mapState("raster", ["activeRasterLayer"]),
    activeRegionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    filterRaster() {
      return this.rasterLayer.filter(
        layer => layer.product === "tur" || layer.product === "sst"
      );
    },
    checkAvailableTimesteps() {
      if (this.selectedStep) {
        const availableTimesteps = this.datetimeSelection.filter(
          step => step.datetime === this.selectedStep.datetime
        );
        if (!availableTimesteps.length) {
          this.selectedStep = this.datetimeSelection[0];
        }
      } else {
        this.selectedStep = this.datetimeSelection[0];
      }
    }
  },
  watch: {
    selectedStep() {
      if (this.selectedLayer) {
        this.addLayers(
          this.selectedLayer.layerId,
          this.selectedStep,
          this.accessToken,
          "visualize"
        );
        this.$emit("setActiveLayer", {
          datetime: this.selectedStep,
          layer: this.selectedLayer
        });
      }
    },
    "activeRasterLayer.layerId"() {
      if (
        this.activeRasterLayer.product !== "tur" &&
        this.activeRasterLayer.product !== "sst"
      ) {
        this.panel = null;
      }
      if (!this.activeRasterLayer?.layerId) {
        this.$emit("definePanel");
      }
    },
    async panel() {
      if (this.place == "visualize" && this.selectedLayer) {
        this.removeRasterLayer(this.selectedLayer.layerId);
      }
      if (this.panel !== undefined) {
        this.selectedLayer = this.layerSelection[this.panel];
        this.datetimeSelection = await this.fetchTimesteps(
          this.selectedLayer.layerId
        );
        this.checkAvailableTimesteps();
        this.addLayers(
          this.selectedLayer.layerId,
          this.selectedStep,
          this.accessToken,
          "visualize"
        );
        this.$emit("setActiveLayer", {
          datetime: this.selectedStep,
          layer: this.selectedLayer
        });
      } else {
        this.setLayerTimesteps([]);
        this.$store.dispatch("raster/setActiveLayer", {});
        if (this.place !== "visualize") {
          this.datetimeSelection.forEach(step => {
            this.removeRasterLayer(
              this.selectedLayer.layerId,
              "raster",
              step.datetime
            );
          });
        }
      }
    }
  },
  async created() {
    this.layerSelection = this.filterRaster();
  }
};
</script>

<style>
.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel-header::before {
  background-color: #1d4180 !important;
}
</style>
