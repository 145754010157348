<template>
  <div style="width: 100%">
    <v-expansion-panel>
      <expansion-header :text="$t('coastMenu.changeMap')"></expansion-header>
      <v-expansion-panel-content>
        <v-expansion-panels accordion focusable v-model="panel">
          <v-expansion-panel
            v-for="layer in layerSelection"
            :key="layer.layerId"
          >
            <expansion-header
              :text="layer.title"
              :class-properties="'subtitle-1 py-0'"
            ></expansion-header>
            <v-expansion-panel-content>
              <v-row v-if="datetimeSelection.length">
                <v-col cols="12">
                  <v-select
                    :label="$t('datetime')"
                    :items="datetimeSelection"
                    :item-text="datetimeFormatChange"
                    return-object
                    hide-details
                    v-model="selectedStep"
                  >
                    <v-icon
                      slot="prepend"
                      :disabled="
                        disableNextButton(datetimeSelection, selectedStep)
                      "
                      @click="
                        selectedStep = previousDate(
                          datetimeSelection,
                          selectedStep
                        )
                      "
                    >
                      mdi-menu-left
                    </v-icon>
                    <v-icon
                      slot="append-outer"
                      :disabled="
                        disablePreviousButton(datetimeSelection, selectedStep)
                      "
                      @click="
                        selectedStep = nextDate(datetimeSelection, selectedStep)
                      "
                    >
                      mdi-menu-right
                    </v-icon>
                  </v-select>
                </v-col>
                <!--                <v-col class="pt-7">-->
                <!--                  <v-icon slot="prepend-inner" @click="confirmDelete()">-->
                <!--                    mdi-delete-->
                <!--                  </v-icon>-->
                <!--                </v-col>-->
              </v-row>
              <span v-else style="color: #1D4180">{{
                $t("coastMenu.NewChangeMapWillBeAppearSoon")
              }}</span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-dialog v-model="confirmationDialog" width="500">
      <v-card v-if="selectedLayer">
        <v-card-title> {{ $t("deleteConfirmation") }}</v-card-title>
        <v-card-text
          >{{
            selectedLayer.title +
              ":" +
              " " +
              (selectedStep && selectedStep.datetime
                ? selectedStep.datetime.replace("T", "\u00A0\u00A0")
                : "")
          }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-progress-circular
            v-if="isDeleting"
            :size="30"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn color="red" text @click="deleteScene" :disabled="isDeleting"
            >{{ $t("delete") }}
          </v-btn>
          <v-btn color="primary" text @click="confirmationDialog = false"
            >{{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExpansionHeader from "@/core/components/menu/coastMenu/ExpansionHeader.vue";
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "ChangeMapEntry",
  components: { ExpansionHeader },
  mixins: [coastLayerMixin],
  data: () => ({
    panel: null,
    selectedLayer: null,
    selectedStep: null,
    datetimeSelection: [],
    layerSelection: [],
    confirmationDialog: false,
    isDeleting: false
  }),
  props: {
    rasterLayer: Array,
    place: String
  },
  computed: {
    ...mapState("raster", ["activeRasterLayer"]),
    activeRegionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    datetimeFormatChange(item) {
      let dateTime = item.datetime.replaceAll("T", "\u00A0");
      dateTime = dateTime.replaceAll("-", " - ");
      return dateTime;
    },
    filterRaster() {
      return this.rasterLayer.filter(layer =>
        layer.product.includes("-change")
      );
    },
    checkAvailableTimesteps() {
      if (this.selectedStep) {
        const availableTimesteps = this.datetimeSelection.filter(
          step => step.datetime === this.selectedStep.datetime
        );
        if (!availableTimesteps.length) {
          this.selectedStep = this.datetimeSelection[0];
        }
      } else {
        this.selectedStep = this.datetimeSelection[0];
      }
    },
    confirmDelete() {
      this.confirmationDialog = true;
    },
    async deleteScene() {
      this.isDeleting = true;
      const regionId = this.selectedLayer.regionId;
      const dt = this.selectedStep.datetime;

      const layerId = this.selectedLayer.layerId;
      const url = `/userdata/raster-data/region/${regionId}/layer/${layerId}/datetime/${dt}`;
      await axios.delete(url);

      this.isDeleting = false;

      this.confirmationDialog = false;
      this.$emit("reloadDateTimes");
    }
  },
  watch: {
    selectedStep() {
      if (this.selectedLayer) {
        this.addLayers(
          this.selectedLayer.layerId,
          this.selectedStep,
          this.accessToken,
          "visualize"
        );
        this.$emit("setActiveLayer", {
          datetime: this.selectedStep,
          layer: this.selectedLayer
        });
      }
    },
    async panel() {
      if (this.place == "visualize" && this.selectedLayer) {
        this.removeRasterLayer(this.selectedLayer.layerId);
      }
      if (this.panel !== undefined) {
        this.selectedLayer = this.layerSelection[this.panel];
        this.datetimeSelection = await this.fetchTimesteps(
          this.selectedLayer.layerId
        );
        this.checkAvailableTimesteps();
        this.addLayers(
          this.selectedLayer.layerId,
          this.selectedStep,
          this.accessToken,
          "visualize"
        );
        this.$emit("setActiveLayer", {
          datetime: this.selectedStep,
          layer: this.selectedLayer
        });
      } else {
        this.$store.dispatch("raster/setActiveLayer", {});
        if (this.place !== "visualize") {
          this.datetimeSelection.forEach(step => {
            this.removeRasterLayer(
              this.selectedLayer.layerId,
              "raster",
              step.datetime
            );
          });
        }
      }
    },
    "activeRasterLayer.layerId"() {
      if (
        this.activeRasterLayer.layerId &&
        !this.activeRasterLayer?.product.includes("change")
      ) {
        this.panel = null;
      }
    }
  },
  async created() {
    this.layerSelection = this.filterRaster();
  }
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
