<template>
  <v-expansion-panel>
    <expansion-header :text="$t('coastMenu.tideData')"></expansion-header>
    <v-expansion-panel-content>
      <near-real-time
        v-if="stationList.length"
        :station-list="stationList"
        :parameter-list="parameterList"
      ></near-real-time>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ExpansionHeader from "@/core/components/menu/coastMenu/ExpansionHeader.vue";
import axios from "axios";
import NearRealTime from "@/core/components/menu/coastMenu/NearRealTime.vue";

export default {
  name: "TideDataEntry",
  components: { NearRealTime, ExpansionHeader },
  data: () => ({
    headerPanel: [],
    stationList: [],
    parameterList: []
  }),
  methods: {
    async fetchTideParameter() {
      const response = await axios.get(`/external/qld/tides/parameters`);
      const filtered = response.data.filter(
        parameter => parameter.name !== "Residual"
      );
      const tideObject = [
        { full_name: "Tide Level (LAT)", data: filtered, type: "tides" }
      ];
      return tideObject;
    },
    async fetchTideStations() {
      const response = await axios.get(`/external/qld/tides/sites`);
      const filtered = response.data.filter(
        site => site.site_name == "maroochydore"
      );
      return filtered;
    }
  },

  async created() {
    this.parameterList = await this.fetchTideParameter();
    this.stationList = await this.fetchTideStations();
  }
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
