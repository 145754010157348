<template>
  <v-expansion-panel-header>
    <template v-slot:default="">
      <v-row align="center" justify="center">
        <v-col :class="classProperties">{{ text }} </v-col>
      </v-row>
    </template>
  </v-expansion-panel-header>
</template>

<script>
export default {
  name: "ExpansionHeader",
  props: {
    text: String,
    classProperties: { type: String, default: "ml-1 subtitle-1" }
  }
};
</script>

<style scoped></style>
