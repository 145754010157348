<template>
  <v-expansion-panel>
    <expansion-header
      :text="$t('coastMenu.depthElevShoreline')"
    ></expansion-header>
    <v-expansion-panel-content id="innerExPan">
      <v-expansion-panels accordion focusable single v-model="panel1">
        <v-expansion-panel
          v-for="(layer, index) in layerSelection.filter(
            layer => layer.product === 'sdb'
          )"
          :key="layer.layerId"
          active-class="py-0"
        >
          <expansion-header
            :text="layer.title"
            :class-properties="'subtitle-1 py-0'"
          ></expansion-header>
          <single-data-entry
            :panel="panel1"
            :index="index"
            :selected-layer="layer"
            :layer-selection="layerSelection"
            @changeInStep="changeTimeStep"
            @definePanel="panel1 = null"
          ></single-data-entry>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels accordion focusable v-model="panel2">
        <v-expansion-panel
          v-for="(layer, index) in layerSelection.filter(
            layer => layer.product === 'dem'
          )"
          :key="layer.layerId"
          active-class="py-0"
        >
          <expansion-header
            :text="layer.title"
            :class-properties="'subtitle-1 py-0'"
          ></expansion-header>
          <single-data-entry
            :panel="panel2"
            :index="index"
            :selected-layer="layer"
            :layer-selection="layerSelection"
            @changeInStep="changeTimeStep"
            @definePanel="panel2 = null"
          ></single-data-entry>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import coastLayerMixin from "@/core/mixins/coastLayer.mixin";
import { mapActions } from "vuex";
import SingleDataEntry from "@/core/components/menu/coastMenu/SingleDataEntry.vue";
import ExpansionHeader from "@/core/components/menu/coastMenu/ExpansionHeader.vue";

export default {
  name: "DepthElevationShorelineEntry",
  components: { ExpansionHeader, SingleDataEntry },
  mixins: [coastLayerMixin],
  data: () => ({
    panel1: 0,
    panel2: null,
    layerSelection: []
  }),
  props: {
    rasterLayer: Array
  },
  methods: {
    ...mapActions("raster", ["setSdbLayer", "setDemLayer"]),
    filterRaster() {
      let layerSelection = this.rasterLayer.filter(
        layer => layer.product === "sdb" || layer.product === "dem"
      );
      layerSelection.sort((a, b) =>
        b.title.toUpperCase().localeCompare(a.title.toUpperCase())
      );
      return layerSelection;
    },
    changeTimeStep(step, layer) {
      if (layer) {
        this.$emit("setActiveLayer", { datetime: step, layer: layer });
      }
    }
  },
  async created() {
    this.layerSelection = this.filterRaster();
  }
};
</script>

<style>
.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel-content__wrap {
  padding-bottom: 5px !important;
}
</style>
